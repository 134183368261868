.ant-layout{
    .logo {
      height: 32px;
      margin: 16px;
      display: flex;
      justify-content: center;
      font-weight: 700;
      background: rgba(255, 255, 255, 0.2);
    }
    .site-layout {
      background: #fff;
    }
    .site-layout-background {
      background: #F0F2F5;
      padding: 24px;
      margin: 0;
      min-width: 280px;
    }
    .header-layout-background {
      background-color: #fff;
      display: flex;
      flex-direction: row-reverse;
      font-size: medium;
      font-size: larger;
      div{
        cursor: pointer;
        &:hover{
          color: red;
          transform: scale(1.1);
          transition: all 100ms ease-in;
        }
      }
    }

    .main-content{
      background-color: #fff;
      display: flex;
      min-height: 100%;
      padding: 50px;
    }
    .ant-layout-footer{
      text-align: center;
    }
}